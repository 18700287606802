@import "@styles-v2/common";

.container {
  .icon {
    // sns アイコン は stroke ではなく fill の色を指定
    @include v2-set-svg-color(c(gray-400));
    @include v2-set-svg-size(24px);
  }

  .smallIcon {
    @include v2-set-svg-color(c(gray-400));
    @include v2-set-svg-size(20px);
  }

  &:hover {
    opacity: 1;

    .icon {
      @include v2-set-svg-color(c(gray-500));
    }
  }
}
